#breadcrumb {
    margin-bottom: 5px;
    border-top: 1px solid #eee;
    padding-top: 10px;
    padding-bottom: 3px;
    .btext {
        padding-left: 12px;
        font-size: .75em;
        position: relative;
        &:before{
            content: '';
            position: absolute;
            left: 0px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
            display: inline-block;
            @include sprite($sprite-breadcrumbHome);
        }
    }
    ul {
        margin-left: 7px;
        padding-left: 0;
        display: inline;
        font-size: .75em;
        padding-right: 10px;
        li {
            margin-left: 5px;
            padding-left: 13px;
            display: inline;
            font-weight: 700;
            position: relative;
            &:before{
                content: '';
                position: absolute;
                left: 0px;
                top: 50%;
                transform: translateY(-50%);
                z-index: 1;
                display: inline-block;
                @include sprite($sprite-breadcrumbSpacer);
            }
            &:first-of-type{
                margin-left: -18px;
                &:before{
                    background: none;
                }
            }
        }
    }
    a {
        display: inline;
        color: $gray-dark;
        &:link, &:visited {
            color: $gray-dark;
            text-decoration: none;
        }
        &:hover, &:focus{
            color: $pink;
            text-decoration: none;
        }
    }
}