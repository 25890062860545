.btn{    
    outline: none !important;
    text-decoration: none !important;
}
.btn-fw{
    min-width: 280px;
}
.btn-red{    
    text-transform: uppercase;
    font-weight: 600 !important;
    padding: 8px 12px !important;
    border-radius: 6px!important;
    top: 0;
    @include buttonAvasGraditent(#fff, #fff, #cd1919, #ab0701, #888, #666, #cd1919, #ab0701, darken(#ab0701, 10%));
}
.btn-red-inactive{    
    text-transform: uppercase;
    font-weight: 600 !important;
    padding: 8px 12px !important;
    border-radius: 6px!important;
    top: 0;
    @include buttonAvasInactiveGraditent(#fff, #fff, #ddd, darken(#ddd, 15%), #cd1919, #ab0701, #888, #666, #ddd, #ab0701, darken(#ddd, 25%), darken(#ab0701, 10%));
}

.btn-green{    
    text-transform: uppercase;
    font-weight: 600 !important;
    padding: 8px 12px !important;
    border-radius: 6px!important;
    top: 0;
    @include buttonAvasGraditent(#fff, #fff, #28c333, #22a02b, #888, #666, #28c333, #22a02b, rgba(0,109,0, .45));

    // Additional styles for inactive state
    &.inactive {
        opacity: 0.5;
        pointer-events: none;
        cursor: not-allowed;
        @include buttonAvasGraditent(#ccc, #ccc, #333, #222, #777, #555, #222, #525252, rgba(30,30,30, .45));
    }
}
.btn-blue{
    text-transform: uppercase;
    font-weight: 600 !important;
    padding: 8px 12px !important;
    border-radius: 6px!important;
    top: 0;
    @include buttonAvasGraditent(#fff, #fff, #2f9ce1, #017cca, #888, #666, #2f9ce1, #017cca, rgba(0, 44, 103, .45));
}

.btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0!important;
    border-bottom-right-radius: 0!important;
}
.btn-group > .btn:last-child:not(:first-child), .btn-group > .dropdown-toggle:not(:first-child) {
    border-top-left-radius: 0!important;
    border-bottom-left-radius: 0!important;
}
.btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
    border-radius: 0!important;
}