#nav{
    position: relative;
    background: $white;
    width: 100%;
    .navBtn{
        display: none;
    }
    .navActive{
        display: block;
        color: $white;
        position: relative;
        z-index: 600;
        margin: 0 auto;
        padding: 8px 10px 5px;
        background-color: $green-dark;
        text-align: right;
        top: 0;
        line-height: 30px;
        cursor: pointer;
        .navIcon {
            position: relative;
            cursor: pointer;
            font-weight: 800;
            font-size: 16px;
            &:before{
                content: '';
                position: absolute;
                left: -22px;
                z-index: 1;
                display: inline-block;
                top: 48%;
                transform: translateY(-50%);
                @include sprite($sprite-navIconClose);
            }
        }

    }
    &.active{
        margin: 0 auto;
        width: 100%;
        background: $green;
        box-shadow: none;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 500;
        height: 100%;
        overflow: scroll;
        > ul{
            width: 100%;
            margin: 0 auto;
            display: block;
            margin: 0;
            margin-top: 0px;
            padding: 0;
            margin-top: 10px;
        }
    }
    ul{
        display: none;
        text-align: center;
        margin: 0;
        padding: 0;
        li{
            display: block;
            position: relative;
            z-index: 500;
            margin: 0;
            padding: 0;
            border-bottom: 1px solid #075a17;
            a, > span {
                display: inline-block;
                margin: 0;
                font-family: Arial,Helvetica,sans-serif;
                color: $white;
                text-decoration: none;
                font-size: 15px;
                font-weight: normal;
                padding: 12px 20px;
            }
            .fa{
                position: absolute;
                left: 20px;
                top: 50%;
                transform: translateY(-50%);
            }
            ul {
                display: none;
                margin: 0;
                padding: 0;
                background: $green-dark;
                position: relative;
                top: 0;
                left: 0;
                width: auto;
                z-index: 480;
                box-shadow: none;
                border-top: 1px solid #075a17;
                li {
                    display: block;
                    margin: 0;
                    padding: 0;
                    position: relative;
                    margin: 0;
                    margin-left: 0px;
                    padding: 0;
                    a, > span{
                        float: none;
                        display: block;
                        margin: 0;
                        &:hover {
                            background: $green;
                        }
                    }
                    &:last-child {
                        border: 0;
                    }
                }
            }
        }
    }
    .subnavArrow{
        display: block;
        margin: 0 auto;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        cursor: pointer;
        &:before{
            content: '';
            position: absolute;
            right: 0;
            top: 5px;
            width: 55px;
            height: 35px;
            z-index: 1;
            display: inline-block;
            @include sprite($sprite-subNavArrow);
        }
        &.active{
            &:before{
                @include sprite-position($sprite-subNavArrowClose);
            }
        }
    }
}

#nav-login,
#nav-delivery-status{
    display: inline-block;
}

nav#nav {
    ul {
        li {
            &:hover {
                >ul {
                    display: block !important;
                }
            }
        }
    }
}