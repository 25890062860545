.text {
    &-italic   { font-style: italic !important; }
    &-justify  { text-align: justify !important; }
    &-wrap     { white-space: normal !important; }
    &-nowrap   { white-space: nowrap !important; }

    &-decoration-none { text-decoration: none !important; }

    &-lowercase  { text-transform: lowercase !important; }
    &-uppercase  { text-transform: uppercase !important; }
    &-capitalize { text-transform: capitalize !important; }

    &-break {
        word-break: break-word !important; // IE & < Edge 18
        overflow-wrap: break-word !important;
    }
    
    &-truncate {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: $green;
        font-weight: 600;
        span{
            color: $text-color;
            font-weight: 400;
        }
    }
    &-strike{
        text-decoration: line-through;
    }
}


// Weight
.font-weight-light   { font-weight: 200 !important; }
.font-weight-lighter { font-weight: 300 !important; }
.font-weight-normal  { font-weight: 400 !important; }
.font-weight-bold    { font-weight: 600 !important; }
.font-weight-bolder  { font-weight: 800 !important; }

.font-serif{
    font-family: "Times New Roman",Times,serif;
}

@each $color, $value in $colors {
    @include text-emphasis-variant(".text-#{$color}", $value);
}

a, .btn, .dropdown-toggle {
    opacity: 1;
    @include transition(all .4s ease);
    &:hover{
        outline: none;
    }
    &:focus{
        outline: none;
    }
}
.btn{
    white-space: normal!important;
}

a, .btn-link{
    outline: none;
    border: none;
    text-decoration: underline;
    &:link, &:visited {
        text-decoration: underline;
    }
    &:visited {
        color: $purple-dark;
    }
    &:hover {
        text-decoration: none;
    }
    &:active {
        color: $brown;
    }
}

#int-main-fullwidth #big-headline {
    font-size: 32px;
    text-align: left;
    font-family: Georgia, "Times New Roman", Times, serif;
    color: #855403;
    font-weight: normal;
    margin-bottom: 10px;
    background: none;
    border-bottom: 1px solid #eee;
}
.big-headline {
    font-size: 28px;
    text-align: left;
    font-family: Georgia, "Times New Roman", Times, serif;
    color: #333;
    font-weight: normal;
    margin-bottom: 10px;
    background: none;
    border-bottom: 1px solid #ccc;
    margin-top: 10px;
    padding-bottom: 5px;
}

#content-content h2 a, #content-blog h2 a {
    text-decoration: none;
}
.category-title {
    font-size: 28px!important;
    color: #333!important;
    small {
        color: #855403;
    }
}

.light-content-box h2, .round-content-box h2, .checkout-headline, .account-content-text h2 {
    font-family: "Lucida Sans Unicode", "Lucida Grande", Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 1.1em;
    background: none;
    border-bottom: 1px solid #ddd;
    margin: 0;
    padding: 0;
    padding-bottom: 5px;
    margin-bottom: 5px;
    color: #666
}

#account-content h2 {
    margin-left: 4px
}
