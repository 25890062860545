#testim-prod, #testim-home {
    padding-top: 3px;
    padding-left: 44px;    
    position: relative;   
    &:before{
        content: '';
        position: absolute;
        left: 5px;
        display: inline-block;
        @include sprite($sprite-quote);
    }
}
#testim-prod .author, #testim-home .author {
    display: block;
    font-weight: bold;
    color: #666;
    font-size: 1.2em;
    font-style: italic
}