.review {
    padding: 0;
    margin: 0;
    padding: 10px;
    background: $white;
    box-shadow: $box-shadow-sm;
    margin-bottom: 10px;
    border-radius: $border-radius-base;
}
.reviewPublished{
    display: inline;
    color: #333;
}
.reviewPerson{
    display: inline;
    color: #666;
    font-weight: bold;
    margin-top: 3px;
}