// Additional mixin to get sprite image and position
@mixin spriteImagePosition($sprite) {
    @include sprite-image($sprite);
    @include sprite-position($sprite);
}

// Mixin to generate gradient buttons
@mixin buttonAvasGraditent($color, $colorHover, $background, $backgroundHover, $shadow, $shadowText, $border, $borderHover, $borderBottom) {
    color: $color!important;
    background-image: linear-gradient(to bottom, $background 0%, $background 40%, $backgroundHover 100%);    
    background-color: $background;
    border-color: $border;
    text-shadow: 1px 1px 1px $shadowText;
    -moz-box-shadow: 0 0 4px $shadow;
    -webkit-box-shadow: 0 0 4px $shadow;
    box-shadow: 0 0 4px $shadow;
    position: relative;
    font-weight: 600;
    &:focus,
        &.focus {
        color: $colorHover!important;
        background-image: linear-gradient(to bottom, $backgroundHover 0%, $backgroundHover 50%, $background 100%)!important;  
        background-color: $backgroundHover;
        border-color: $borderHover;
    }
    &:hover {
        color: $colorHover!important;
        background-image: linear-gradient(to bottom, $backgroundHover 0%, $backgroundHover 50%, $background 100%)!important; 
        background-color: $backgroundHover;
        border-color: $borderHover;
    }
    &:active,
        &.active,
        .open > &.dropdown-toggle {
        color: $colorHover!important;
        background-image: linear-gradient(to bottom, $backgroundHover 0%, $backgroundHover 50%, $background 100%)!important; 
        background-color: $backgroundHover;
        border-color: $borderHover;

        &:hover,
            &:focus,
            &.focus {
            color: $colorHover!important;
            background-image: linear-gradient(to bottom, $backgroundHover 0%, $backgroundHover 50%, $background 100%)!important; 
            background-color: $backgroundHover;
            border-color: $borderHover;
        }
    }
    &:active:after,
        &.active:after {
        display: none;
    }
    &:active,
        &.active,
        .open > &.dropdown-toggle {
        background-image: none;
    }
    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        &:hover,
            &:focus,
            &.focus {
            background-color: $background;
            border-color: $border;
        }
    }
    &:after {
        content: "";
        position: absolute;
        display: block;
        left: 0;
        right: 0;
        height: 4px;
        width: 100%;
        bottom: -1px;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        z-index: 0;
        background: $borderBottom;
    }

    .badge {
        color: $background;
        background-color: $color;
    }
}

// Mixin to generate gradient buttons
@mixin buttonAvasInactiveGraditent(
$color, $colorHover, $background, $background2, $backgroundHover, $backgroundHover2, $shadow, $shadowText, $border, $borderHover, $borderBottom, $borderBottomHover) {
    color: $color!important;
    background-image: linear-gradient(to bottom, $background 0%, $background 40%, $background2 100%);    
    background-color: $background;
    border-color: $border;
    text-shadow: 1px 1px 1px $shadowText;
    -moz-box-shadow: 0 0 4px $shadow;
    -webkit-box-shadow: 0 0 4px $shadow;
    box-shadow: 0 0 4px $shadow;
    position: relative;
    font-weight: 600;
    &:after {
        content: "";
        position: absolute;
        display: block;
        left: 0;
        right: 0;
        height: 4px;
        width: 100%;
        bottom: -1px;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        z-index: 0;
        background: $borderBottom;
    }
    &:focus,
        &.focus {
        color: $colorHover!important;
        background-image: linear-gradient(to bottom, $backgroundHover 0%, $backgroundHover 50%, $backgroundHover2 100%)!important;  
        background-color: $backgroundHover;
        border-color: $borderHover;
    }
    &:hover {
        color: $colorHover!important;
        background-image: linear-gradient(to bottom, $backgroundHover 0%, $backgroundHover 50%, $backgroundHover2 100%)!important; 
        background-color: $backgroundHover;
        border-color: $borderHover;
        &:after {
            background: $borderBottomHover;
        }

    }
    &:active,
        &.active,
        .open > &.dropdown-toggle {
        color: $colorHover!important;
        background-image: linear-gradient(to bottom, $backgroundHover 0%, $backgroundHover 50%, $backgroundHover2 100%)!important; 
        background-color: $backgroundHover;
        border-color: $borderHover;

        &:hover,
            &:focus,
            &.focus {
            color: $colorHover!important;
            background-image: linear-gradient(to bottom, $backgroundHover 0%, $backgroundHover 50%, $backgroundHover2 100%)!important; 
            background-color: $backgroundHover;
            border-color: $borderHover;
        }
    }
    &:active:after,
        &.active:after {
        display: none;
    }
    &:active,
        &.active,
        .open > &.dropdown-toggle {
        background-image: none;
    }
    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        &:hover,
            &:focus,
            &.focus {
            background-color: $background;
            border-color: $border;
        }
    }

    .badge {
        color: $background;
        background-color: $color;
    }
}

// mixin to generate buttons
@mixin buttonAvas($color, $colorHover, $background, $backgroundHover, $border, $borderHover) {
    color: $color;
    background-color: $background;
    border-color: $border;

    &:focus,
        &.focus {
        color: $colorHover;
        background-color: $backgroundHover;
        border-color: $borderHover;
    }
    &:hover {
        color: $colorHover;
        background-color: $backgroundHover;
        border-color: $borderHover;
    }
    &:active,
        &.active,
        .open > &.dropdown-toggle {
        color: $colorHover;
        background-color: $backgroundHover;
        border-color: $borderHover;

        &:hover,
            &:focus,
            &.focus {
            color: $colorHover;
            background-color: $backgroundHover;
            border-color: $borderHover;
        }
    }
    &:active,
        &.active,
        .open > &.dropdown-toggle {
        background-image: none;
    }
    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        &:hover,
            &:focus,
            &.focus {
            background-color: $background;
            border-color: $border;
        }
    }

    .badge {
        color: $background;
        background-color: $color;
    }
}

// Hinde text for human, screen reader friendly
@mixin textSrHide() {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

// Show hidden text
@mixin textSrShow() {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal; 
}

// Typography
@mixin text-emphasis-variant($parent, $color) {
    #{$parent} {
        color: $color !important;
    }
    @if $emphasized-link-hover-darken-percentage != 0 {
        a#{$parent} {
            @include hover-focus() {
                color: darken($color, $emphasized-link-hover-darken-percentage) !important;
            }
        }
    }
}


// Contextual backgrounds
@mixin bg-variant($parent, $color) {
    #{$parent} {
        background-color: $color !important;
    }
    a#{$parent},
    button#{$parent} {
        @include hover-focus() {
            background-color: darken($color, 10%) !important;
        }
    }
}

// Hover mixin
@mixin hover() {
    &:hover { @content; }
}

@mixin hover-focus() {
    &:hover,
        &:focus {
        @content;
    }
}

@mixin plain-hover-focus() {
    &,
    &:hover,
        &:focus {
        @content;
    }
}

@mixin hover-focus-active() {
    &:hover,
        &:focus,
        &:active {
        @content;
    }
}

// Breakpoint viewport sizes and media queries.
//
// Breakpoints are defined as a map of (name: minimum width), order from small to large:
//
//    (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px)
//
// The map defined in the `$grid-breakpoints` global variable is used as the `$breakpoints` argument by default.

// Name of the next breakpoint, or null for the last breakpoint.
//
//    >> breakpoint-next(sm)
//    md
//    >> breakpoint-next(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    md
//    >> breakpoint-next(sm, $breakpoint-names: (xs sm md lg xl))
//    md
@function breakpoint-next($name, $breakpoints: $grid-breakpoints, $breakpoint-names: map-keys($breakpoints)) {
  $n: index($breakpoint-names, $name);
  @return if($n != null and $n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}

// Minimum breakpoint width. Null for the smallest (first) breakpoint.
//
//    >> breakpoint-min(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    576px
@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}

// Maximum breakpoint width. Null for the largest (last) breakpoint.
// The maximum value is calculated as the minimum of the next one less 0.02px
// to work around the limitations of `min-` and `max-` prefixes and viewports with fractional widths.
// See https://www.w3.org/TR/mediaqueries-4/#mq-min-max
// Uses 0.02px rather than 0.01px to work around a current rounding bug in Safari.
// See https://bugs.webkit.org/show_bug.cgi?id=178261
//
//    >> breakpoint-max(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    767.98px
@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
  $next: breakpoint-next($name, $breakpoints);
  @return if($next, breakpoint-min($next, $breakpoints) - .02, null);
}

// Returns a blank string if smallest breakpoint, otherwise returns the name with a dash in front.
// Useful for making responsive utilities.
//
//    >> breakpoint-infix(xs, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    ""  (Returns a blank string)
//    >> breakpoint-infix(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    "-sm"
@function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
  @return if(breakpoint-min($name, $breakpoints) == null, "", "-#{$name}");
}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media of at most the maximum breakpoint width. No query for the largest breakpoint.
// Makes the @content apply to the given breakpoint and narrower.
@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media that spans multiple breakpoint widths.
// Makes the @content apply between the min and max breakpoints
@mixin media-breakpoint-between($lower, $upper, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($lower, $breakpoints);
  $max: breakpoint-max($upper, $breakpoints);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($lower, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include media-breakpoint-down($upper, $breakpoints) {
      @content;
    }
  }
}

// Media between the breakpoint's minimum and maximum widths.
// No minimum for the smallest breakpoint, and no maximum for the largest one.
// Makes the @content apply only to the given breakpoint, not viewports any wider or narrower.
@mixin media-breakpoint-only($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  $max: breakpoint-max($name, $breakpoints);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($name, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include media-breakpoint-down($name, $breakpoints) {
      @content;
    }
  }
}
