#topOfferHeader{
    text-align: center;
    padding: 7px 0;
    font-size: 13px;
}
#topLinks{
    text-align: right;
}
.topLinksItem, #contact-us-sign, #call-number-mobile{
    color: $white!important;
    text-decoration: none!important;
    font-size: 13px;
    position: relative;
    padding: 5px 8px;
    display: inline-block;
    &:hover, &:focus{
        background-color: $green-dark;
    }
}
.phoneHideOnMobile{
    display: none!important;
}
#phone-modal{
    text-align: center;
}
#toll-free-sign {
    float: left;
    font-size: 11px !important;
    line-height: 9px;
    cursor: default;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0 !important;
}
.navBtn{
    display: inline-block;
    background-color: $green!important;
    text-transform: uppercase;
    font-weight: 800;
    position: absolute;
    left: 0;
    cursor: pointer;
    z-index: 1;
    &:hover, &:focus{
        background-color: $green!important;
    }
}
.navBtn > .fa-lg{
    font-size: 1.5em;
}
#topOrderBox{
    p{
        font-size: 14px!important;
    }
    a{
        font-size: 14px!important;
        color: $green!important;
        &:hover, &:focus{
            color: $pink!important;
        }
        &.tp{
            color: $brown!important;
            &:hover, &:focus{
                color: $pink!important;
            }
        }
    }
    .fa{
        color: $yellow;
    }
}
#topLogoBox{
    text-align: center;
}
#topLogoAddon{
    text-align: center;
}
#topOffer{
    display: none;
}
#topDiscountCode{
    display: none!important;
}
#topDiscountCodeMiddle{
    display: block;
    text-align: center;
    margin-top: 10px;

}
#toplink-login{
    display: none;
}
.cartBadge {
    font-size: 12px;
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 0px 5px 0px 5px;
    position: absolute;
    top: 3px;
    right: 55px;
}