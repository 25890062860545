
.mobile-only { display: none }

img {
    border: 0;
}
address {
    margin: 0.2em 0;
    padding: .5em 0 1em;
    font-size: .75em;
    line-height: 140%;
    font-style: normal
}
:focus, input {
    outline: 0;
    border: 0
}
ul, ol {
    list-style: none
}
/*----- Slip Links -----*/
#skip-link {
    width: 0;
    height: 0;
    font-size: .85em;
    margin: 0;
}
#skip-link li {
    width: 28em;
    text-indent: 0
}
#skip-link a, #skip-link a:link {
    display: inline-block;
    position: absolute;
    top: -556px;
    color: #000;
    cursor: help;
    margin: 0;
    padding: 4px 4px;
    line-height: 1em;
    font-weight: bold;
    text-decoration: underline;
    z-index: 99
}
#skip-link a:visited {
    top: -556px;
    color: #ccc;
    cursor: help;
    text-decoration: underline
}
#skip-link a:active, #skip-link a:focus {
    top: 4px;
    left: 4px;
    color: #393939;
    cursor: help;
    text-decoration: underline
}

.defaultList{
    display: block; 
    list-style-type: disc; 
    margin-top: 1em; 
    margin-bottom: 1em; 
    margin-left: 0;
    margin-right: 0; 
    padding-left: 40px;
}

.well{
    hr{
        background-color: transparent!important;
        border-color: #ddd!important;
    }
}

iframe[name='google_conversion_frame'] {
    height: 0 !important;
    width: 0 !important;
    line-height: 0 !important;
    font-size: 0 !important;
    margin-top: -13px;
    float: left;
}
.ifra img {
    height: 0px !important;
}

hr {
    border: 0;
    color: #b2b2b2;
    border-bottom: 1px #b2b2b2 dashed;
    padding-top: 8px;
    margin-bottom: 4px;
    outline: 0;
    height: 1px;
    clear: both;
    background: #fff !important
}
#awarded-box .img-wrapper {
    text-align: center;
    margin: 3px;
}
#awarded-box .img-wrapper img {
    border: 6px solid #eee;
}

#category-headline {
    font-size: 36px;
    text-align: left;
    font-family: Georgia, "Times New Roman", Times, serif;
    color: #444;
    font-weight: normal;
    padding: 0;
    margin: 0;
    position: absolute;
    bottom: -1px;
    left: 5px;
    text-shadow: 0px 0px 2px #ccc;
}

.price {
    color: $red;
}
.old-price {
    color: $red;
    text-decoration: line-through;
    margin-right: 5px
}
.opt-price {
    color: $red;
}
.img-border {
    border: 1px #eee solid;
}
#show-more-products, .show-pagebreak, #show-more-feat-products, .show-more {
    text-transform: uppercase;
    font-size: 1.2em;
    margin-top: 5px;
    margin-bottom: 5px;
}
.show-more-loader {
    text-align: center;
    border: 1px solid #eee;
    background: #f1f1f1;
}
.show-more-loader-text {
    font-size: 1.2em !important;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-weight: bold;
    color: #666;
    width: 180px;
    background: url(../img/1.0.1/checkout_loader_small.gif) center left no-repeat;
    padding: 7px;
    padding-left: 40px;
    margin: 5px auto;
}

.shop-by-link, .shop-by-link:visited, .shop-by-link:active {
    color: #999;
    text-decoration: none;
    padding-left: 6px;
    text-align: left;
    display: block;
    padding-top: 3px;
    padding-bottom: 0px
}

.ajax-loader-banner-big {
    width: 100%;
    background: #eee;
    margin: 0 auto;
    padding-top: 70px;
    text-align: center;
    z-index: 1;
}
.ajax-loader-banner-big-txt {
    clear: both;
    text-align: center;
    font-size: 36px !important;
    font-family: Georgia, Georgia, serif;
    font-weight: bold;
    color: #666;
    margin-top: 5px;
}
/**
*
* DELIVERY POPUP
*
*/

#delivery-popup-holder {
    position: absolute;
    top: 0;
    left: 0px;
    width: 100%;
    z-index: 10000;
}

#comebackCouponHolder {
    position: absolute;
    top: 0;
    left: 0px;
    width: 500px;
    z-index: 10000;
    border: 1px solid;
    background-color: white;
    padding: 10px;
}

#delivery-popup-background, #comebackCouponBackground, #checkout-cart-loader-overlay, #checkout-loader-overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    background: #fff;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=70);
    -moz-opacity: 0.7;
    -khtml-opacity: 0.7;
    -webkit-opacity: 0.7;
    opacity: 0.7;
}
#delivery-popup {
    background-color: #fff;
    box-shadow: 0 0 13px #666;
    padding: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    height: 250px;
    max-width: 400px;
    margin: auto;
}
#sdp-popup {
    padding: 15px;
    padding-top: 10px;
    padding-bottom: 10px
}
#delivery-popup h2, #sdp-popup h2, .popup-headline {
    font: 1.4em Georgia, "Times New Roman", Times, serif;
    color: #855403;
    text-shadow: 0px 2px 2px #ccc;
    padding: 0;
    margin: 0;
}

#delivery-popup h2 {
    color: #000
}

#delivery-popup h3 {
    padding: 0;
    margin: 0;
    padding-bottom: 5px;
    padding-top: 2px;
    font: 1.2em Georgia, "Times New Roman", Times, serif;
    color: #666;
    text-shadow: 0px 2px 2px #ccc;
}
#delivery-popup p, #sdp-popup p {
    font-size: .9em;
    margin-top: 3px
}
#sdp-popup p {
    margin-bottom: 10px
}
#delivery-popup label#no1 {
    text-align: center;
    font: 1.1em Georgia, "Times New Roman", Times, serif;
    color: #0084ff;
    text-shadow: 0px 2px 2px #ccc;
    display: block;
    padding-top: 5px;
    padding-bottom: 3px;
}
#delivery-popup select, #delivery-popup .txtf {
    border: 1px solid #ccc;
    padding-left: 6px;
    padding-right: 1px;
    padding-top: 1px;
    padding-bottom: 1px;
    color: #333;
    font: .9em Georgia, "Times New Roman", Times, serif;
    width: 80%;
    text-align: right
}

#delivery-popup select,
#delivery-popup .txtf {
    display: block;
    margin: 0 auto;
    border: 1px solid #ccc;
    color: #333;
    font: .9em Georgia, "Times New Roman", Times, serif;
    width: 40%;
    text-align: center
}
.filter-select {
    border: 1px solid #ccc;
    padding-left: 6px;
    padding-right: 1px;
    padding-top: 1px;
    padding-bottom: 1px;
    color: #333;
    font: 1em Georgia, "Times New Roman", Times, serif;
    width: 170px;
}
#delivery-popup label#no2 {
    font: 1.1em Georgia, "Times New Roman", Times, serif;
    color: #0084ff;
    text-shadow: 0px 2px 2px #ccc;
    display: block;
    padding-bottom: 3px;
}
#delivery-popup label#no3 {
    font: 1.1em Georgia, "Times New Roman", Times, serif;
    color: #0084ff;
    text-shadow: 0px 2px 2px #ccc;
    display: block;
    padding-bottom: 3px;
}
#delivery-popup .submit {
    margin-top: 13px;
    width: 100px;
    font-family: Georgia, "Times New Roman", Times, serif;
    font-size: 1em;
}
#delivery-popup .thank-check {
    margin-top: 7px;
    padding-left: 25px;
    padding-top: 1px;
    color: #04a300;
    font-family: Georgia, "Times New Roman", Times, serif;
    font-weight: bold;
    font-size: 1.1em;
    position: relative;
    &:before{
        content: '';
        position: absolute;
        left: 130px;
        z-index: 1;
        display: inline-block;
        @include sprite($sprite-checkLarge);
    }
}
#delivery-popup .error-check {
    margin-top: 7px;
    padding-left: 25px;
    padding-top: 1px;
    color: #a00;
    font-family: Georgia, "Times New Roman", Times, serif;
    font-weight: bold;
    font-size: 1.1em;
    position: relative;
    &:before{
        content: '';
        position: absolute;
        left: 0px;
        z-index: 1;
        display: inline-block;
        @include sprite($sprite-accountIconDelete);
    }
}
#delivery-popup #close-popup-link, #sdp-popup #close-popup-link, #general-popup-holder #close-popup-link, #general-popup-wide-holder #close-popup-link, #facebox-popup-holder #close-popup-link {
    position: absolute;
    right: -5px;
    top: -5px;
    border: 0;
    outline: none;
    z-index: 11;
}
#delivery-popup #close-popup-link img, #sdp-popup #close-popup-link img, #general-popup-holder #close-popup-link img, #general-popup-wide-holder #close-popup-link img, #facebox-popup-holder #close-popup-link img {
    position: absolute;
    right: -7px;
    top: -7px;
    border: 0;
    outline: none;
}

#ajax-loader-banner {
    background: #eee none repeat scroll 0 0;
    left: 0;
    margin: 0 auto;
    padding-bottom: 76px;
    padding-top: 40px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: 10;
}
#ajax-loader-banner-txt {
    clear: both;
    text-align: center;
    font-size: 36px !important;
    font-family: Georgia, Georgia, serif;
    font-weight: bold;
    color: #666;
    margin-top: 5px;
}

.floatleft {
    float: left !important
}
.floatright {
    float: right !important
}
.clear {
    clear: both
}
#int-main-fullwidth p {
    padding: 8px 12px 8px 12px;
}
.footer-content-space {
	margin-bottom: 400px;
}
.collection-box p {
    font-size: .75em;
    padding: 8px 25px 8px 5px;
}

.collection-box img {
    max-width: 100%;
}
/*----- Body -----*/

#header {
    clear: both;
    position: relative;
    height: 110px;
    margin-bottom: 50px;
}/*----- Header -----*/
#logo {
    position: absolute;
    top: 8px;
    left: 0px;
    cursor: pointer
}

.list {
    margin: 0 0 8px 25px;
    font-size: .9em
}
.list li {
    padding: 5px 0 5px 15px;
    position: relative;    
    &:before{
        content: '';
        position: absolute;
        top:8px;
        left: 0px;
        z-index: 1;
        display: inline-block;
        @include sprite($sprite-arrow2);
    }
}
.list li a {
    color: #000
}
.list li ul.sublist {
    margin: 10px 0 0;
    color: #5b5b5b
}
.list li ul.sublist li {
    padding-left: 15px;    
    &:before{
        @include sprite($sprite-arrowSub);
    }
}

.secure-payments {
    padding-left: 22px;
    color: #f1f1f1;
    font-size: .7em;
    font-weight: 700;
    text-transform: capitalize;
    line-height: 29px;
    width: 9em;
    text-align: center;
    position: relative;    
    &:before{
        content: '';
        position: absolute;
        top: 50%;
        left: 0px;
        transform: translateY(-50%);
        z-index: 1;
        display: inline-block;
        @include sprite($sprite-secure);
    }
}

.rgt-links, .rgt-links2, .lft-links {
    padding: 0 10px 10px 12px
}
.rgt-links li, .rgt-links2 li, .lft-links li {
    font-size: .75em;
    padding: 4px 0;
    line-height: 18px
}
.rgt-links2 li, .lft-links li {
    padding-left: 12px;    
    position: relative;    
    &:before{
        content: '';
        position: absolute;
        top:8px;
        left: 0px;
        z-index: 1;
        display: inline-block;
        @include sprite($sprite-arrow2);
    }
}
.lft-links li {
    margin-left: 12px;
    padding: 0 0 3px 12px;
}

.city-content h2, .city-content h1, .city-content h3, #content-content h2, #content-blog h2, #content-blog-post h2 {
    font-size: 24px;
    text-align: left;
    font-family: Georgia, "Times New Roman", Times, serif;
    color: #333;
    font-weight: normal;
    margin-bottom: 10px;
    background: none;
    border-bottom: 1px solid #ccc;
    margin-top: 10px;
    padding-bottom: 5px;
}

.checkout-quest-box-widget {
    padding: 5px;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 2px;
    margin-bottom: 2px;
    margin-left: 5px;
    margin-top: 0px;
}
.checkout-quest-box-widget .center-button {
    margin-top: 0
}
.checkout-quest-box-widget h2 {
    border: 0;
    margin: 0;
    font-size: 18px;
    color: #3399ff;
    font-weight: bold;
    padding-right: 2px;
    padding-top: 0;
    padding-bottom: 24px;
    padding-left: 45px;
    margin-bottom: 15px;
    padding-top: 24px;
    position: relative;
    &:before{
        content: '';
        position: absolute;
        left: -5px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        display: inline-block;
        @include sprite($sprite-accountLoginIconQuestion);
    }
}
.checkout-quest-box-widget ul {
    padding: 0;
    margin: 0;
    margin-left: 0px;
}
.checkout-quest-box-widget ul li {
    padding: 3px;
    margin-left: 20px;
    font-weight: normal;
    font-size: 0.8em;
    padding-left: 0;
    position: relative;
    &:before{
        content: '';
        position: absolute;
        left: -15px;
        top: 5px;
        z-index: 1;
        display: inline-block;
        @include sprite($sprite-checkoutLoginListStyle);
    }
}

.checkout-hint {
    padding: 5px;
    background: #e4f2ff;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    border: 1px solid #c7e4ff;
    color: #333;
    font-weight: bold;
    font-size: 1.1em;
    margin-left: 20px;
    width: 350px;
}
.checkout-hint-arrow, .delivcheckout-hint-arrow {
    position: absolute;
    top: 10px;
    left: 0px;
    display: none;
    &:before{
        content: '';
        position: absolute;
        left: 0px;
        top: 24px;
        z-index: 1;
        display: inline-block;
        @include sprite($sprite-checkoutHintArrow);
    }
}

.chk-select.invalid, .chk-input.invalid {
    border-color: #a00
}
.reward-points-widget {
    padding-left: 60px;
    padding-top: 2px;
    margin-left: 1px;
    margin-bottom: 1px;
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    position: relative;
    &:before{
        content: '';
        position: absolute;
        left: 0px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        display: inline-block;
        @include sprite($sprite-rewardPointsBig);
    }
}
.reward-points-large {
    font-size: 48px;
    font-weight: bold;
    color: #379337;
    float: left
}
.reward-points-large-worth {
    font-size: 20px;
    color: #666;
    float: right;
    margin-left: 20px;
    margin-top: 15px
}
.reward-points-large-worth-green {
    color: #379337;
    font-weight: bold;
}
.reward-points-small {
    float: left;
    margin-left: 5px;
}
.reward-points-points {
    font-size: 24px;
    font-weight: normal;
    color: #666;
    display: block;
    margin-top: 5px;
}
.reward-points-collected {
    font-size: 16px;
    font-weight: normal;
    color: #666;
    display: block;
    margin-top: -6px;
}
#reward-margined {
    margin-top: 10px;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 10px;
    padding-bottom: 0;
    padding-top: 5px
}
.chk-reward-pts {
    padding-left: 60px;
    padding-top: 2px;
    height: 60px;
    margin-left: 1px;
    margin-bottom: 1px;
    position: relative;
    &:before{
        content: '';
        position: absolute;
        left: 0px;
        z-index: 1;
        display: inline-block;
        @include sprite($sprite-rewardPointsBig);
    }
}
.reward-relative {
    position: relative
}
.chk-reward-large {
    font-size: 24px;
    font-weight: bold;
    color: #379337
}
.reward-info {
    font-size: 1em;
    padding: 10px;
    padding-top: 5px;
    padding-bottom: 0px;
}
.lft-rgt-pad-no-margin {
    padding: 0px 5px;
    margin: 0;
}
.lft-rgt-pad-no-margin .info-box-medium {
    margin: 0;
    padding-left: 50px
}

.rewardpoints-info-box-medium {
    background-color: #f2f2f2 ;
    padding: 12px;
    padding-left: 60px;
    font-size: 1.2em;
    color: #333;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    position: relative;
    &:before{
        content: '';
        position: absolute;
        left: 0px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        display: inline-block;
        @include sprite($sprite-rewardPointsBig);
    }
}
.receipt-addevent-link {
    display: block;
    padding: 3px;
    font-size: 1.1em;
    padding-left: 10px;
    position: relative;
    color: $blue;
    &:before{
        content: '';
        position: absolute;
        left: 0px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        display: inline-block;
        @include sprite($sprite-checkoutLoginListStyle);
    }
}
.larger {
    font-size: 1.2em
}

#content-content p, #content-blog p, #content-blog-post p {
    font-size: 1em;
    padding: 8px;
    padding-left: 0px;
    padding-right: 0px;
}
.seen-items {
    display: inline-block;
    padding: 0px
}
.seen-items li {
    float: left;
    margin: 5px;
    width: 116px;
    height: 123px;
    text-align: center;
    padding-top: 6px;
    background-color: #ffffff;
    border: 3px solid #cdcdcd;
    border-radius: 6px;
}
.invalid {
    border: 1px solid #c00000 !important;
    background: #ffeee5 !important
}

#show-awards {
    margin-right: 10px;
    padding-left: 0;
    &:before{
        background-image: none;
    }
}
#content-content li, #content-blog-post li {
    padding: 5px 0 5px 15px;
    font-size: .9em;
    position: relative;    
    &:before{
        content: '';
        position: absolute;
        top:8px;
        left: 0px;
        z-index: 1;
        display: inline-block;
        @include sprite($sprite-arrow2);
    }
}
#content-content li a, #content-blog-post li a {
    color: #000
}
#content-content li ul.sublist, #content-blog-post li ul.sublist {
    margin: 10px 0 0;
    color: #5b5b5b
}
#content-content li ul.sublist li, #content-blog-post li ul.sublist li {
    background: none;
    padding-left: 15px;
    position: relative;    
    &:before{
        content: '';
        position: absolute;
        top:8px;
        left: 0px;
        z-index: 1;
        display: inline-block;
        @include sprite($sprite-arrowSub);
    }
}
.landing-nav-item {
    padding-top: 0 !important;
    padding-bottom: 2px !important
}
.landing-nav-item a {
    font-size: 1.1em
}
.landing-nav-item strong {
    font-size: 1.1em;
    color: #333
}
.landing-select strong {
    font-size: 1.1em
}
.landing-select {
    padding-bottom: 0;
    padding-top: 20px !important
}
.cities {
    float: left;
    width: 187px;
    padding: 8px 59px 0 0
}
.cities.ctlast {
    padding-right: 0
}
.cities .list {
    padding: 6px 0;
    margin: 0
}
.cities .list li {
    padding: 5px 0 5px 8px;
    &:before{
        background-image: none!important;
    }
}
.cities .list li a:hover {
    color: #165616
}
.landing-sep {
    margin-top: 10px;
    margin-bottom: 5px;
    width: auto;
    height: 1px;
    background: #ccc;
    clear: both
}

.pagination {
    text-align: center
}
.pagination ul {
    padding: 0;
    margin-top: 20px;
    margin-bottom: 20px
}
.pagination ul li {
    display: inline-block;
    margin-left: 3px;
    margin-right: 3px;
    list-style: none
}
.pagination ul li a, .pagination ul li.act-pg span {
    text-align: center;
    padding-top: 3px;
    padding-bottom: 2px;
    display: block;
    height: 21px;
    width: 26px;
    border: 1px solid #999;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background: #eee;
    color: #333;
    font-weight: bold;
    font-size: .9em;
    text-decoration: none
}
.pagination ul li a:hover, .pagination ul li.act-pg span {
    background: #fafafa;
    color: #666;
    -moz-box-shadow: 0 0 3px #333;
    -webkit-box-shadow: 0 0 3px #333;
    box-shadow: 0 0 3px #333
}
.pagination ul li.act-pg span {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none
}

.continue-reading-link {
    margin-top: 0;
    padding-top: 0;
}
.continue-reading-link a {
    font-size: 20px;
    text-align: left;
    font-family: Georgia, "Times New Roman", Times, serif;
    color: #333;
    font-weight: normal;
    background: none;
}
.blog-post {
    margin-bottom: 50px;
    background: #fafafa;
    border: 1px solid #eee;
    padding: 8px
}
.blog-image-wrap {
    text-align: center;
    background: #fff;
    padding: 3px;
    border: 1px solid #eee;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}
.blog-image-wrap img {
    -moz-box-shadow: 0 0 3px #333;
    -webkit-box-shadow: 0 0 3px #333;
    box-shadow: 0 0 3px #333
}
.norm {
    margin-left: 5px;
    font-size: .75em
}
.norm li {
    padding: 2px 0
}
.rgt-img-box {
    padding: 5px 0 10px;
    text-align: center
}
.rgt-img-box img {
    border: 1px solid #eee;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -moz-box-shadow: 0 0 5px #999;
    -webkit-box-shadow: 0 0 5px #999;
    box-shadow: 0 0 5px #999
}
.left-sec, .right-sec {
    float: left;
    width: 20.2em;
    padding-top: 10px
}
.right-sec {
    float: right
}
#category-head {
    position: relative;
    width: 987px;
    height: 125px;
    margin-bottom: 15px
}
#sympathy-collection-link {
    font-size: 22px;
    text-align: left;
    font-family: Georgia, "Times New Roman", Times, serif;
    color: #1b821b !important;
    font-weight: bold;
    padding: 0;
    margin: 0;
    position: absolute;
    bottom: 6px;
    right: 5px;
    text-shadow: 0px 0px 2px #fff;
}

ul{    margin: 0px; padding: 0px; list-style-type: none; }

img{ border: none; padding: 0px; margin: 0px; }

.lft{ float: left; } .rft{ float: right;}
.clear{ clear: both; }

.serviceBar{ margin: 10px auto; }

#serviceBarAbove{ display: block; }

#serviceBarBelow{ display: none; }

.serviceIcons {
    margin: 0 auto;
    float: left;
    width: 70%;
}
.serviceIcons div a{ display: inline-block; background: #eee; width: 100%; cursor: pointer; text-decoration: none; }
.serviceIcons div a:hover{ text-decoration: none; }
.serviceIcons div{
    border: 2px solid white;
    float:left;
    width:25%;
    height: 160px;
}
.serviceIcons div a img{
    padding-right: 5px;
    padding-bottom: 5px;
    padding-top: 5px;
    padding-left: 5px;
    width: 100%;
    height: auto;
    display: block;
}
.serviceIcons div a:last-of-type{
    text-align: center;
    display: block;
    text-decoration: none;
    height: 34px;
    position: relative;
    color: #555;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.2;
}
.serviceIcons div a span{
    width: 100%;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 1;
}
.clearNone {
    display:none;
    clear: both;
}
.payment{
    margin: 0 auto;
    float: right;
    width: 30%;
    border: 4px solid #eee;
    text-align: center;
    height: 160px;
    a {
        padding-left: 20px; 
        color: #000;
        text-decoration: underline;
        display: inline-block;
        font-size: 14px;
        margin: 40px auto 10px;
        font-weight: bold;    
        position: relative;    
        &:before{
            content: '';
            position: absolute;
            top: 50%;
            left: 0px;
            transform: translateY(-50%);
            z-index: 1;
            display: inline-block;
            @include sprite($sprite-lockIconSml);
        }
        &:hover{ 
            text-decoration: none; 
            color: #060;
        }
    }
    img{ 
        display: inline-block;
        width: 60%;
        height: auto;
    }
}


.productSec{margin: 10px auto;}
.productSec h2{ font-family:"Times New Roman", Times, serif; color:#333; font-size:20x; border-bottom:1px solid #bbb; padding-bottom:5px; }

.desktopProds{ display: block; }
.mobileProds{ display: none; }

.prodPanel{ width: 100%; margin: 0 auto; }
.prodBox{ margin: 5px; width: 19%; float: left; border: 1px solid #f8f8f8; background: #f8f8f8; position: relative; z-index: 10; }
.prodBox:hover{ border: 1px solid #f1f1f1; background: #f1f1f1; }
.prodBox a img{ display: block; width: 100%; }
.prodBox a img.sameDay{    width: auto; position: absolute; top: -5px;    right: -8px; z-index: 15; }

.prodInfo{ text-align: center; padding: 10px; }
.prodInfo a{ color: #067919; font-size: 13px; font-weight: 500; height: 40px; display: block; margin: 0 auto; }
.prodInfo a:hover{ text-decoration: none; }
.prodInfo p{ font-size: 12px; }
.prodInfo p.price{ font-size: 14px;    font-weight: bold; }
.prodInfo p.price span{    color: #F00; text-decoration: line-through; }

.moreProds a {
    margin: 5px auto; text-align: center; color: #fff; display: block;
    font-family:"Times New Roman", Times, serif; font-size:20px; background: #0781cf; padding: 10px 0;
    text-transform: uppercase;
}
.moreProds a:hover{ text-decoration: none; }

.hmprod{ float: left; margin: 1px; width: 192px }
.hmprod .product_image_container {position: relative;padding-right:6px;padding-top:6px}
.hmprod .product_thumbnail_link {
    position: absolute; 
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    top: 6px;
    right: 6px;
    z-index: 1;
}
.hmprod .product_image {width:100%}
.hmprod .product_image img {width:100%;border:1px solid #eaeaea;border-bottom:0; height: auto;}
.hmprod .product_image_caption {width: 100%;background:#f1f1f1;padding:2px 0px;margin-top:0;text-align:center}
.hmprod .product_image_stars {margin-top:2px;margin-bottom:-3px}
.hmprod .product-reviews-count {
    color: $blue;
    margin-left: 3px;
    font-size: 12px;
    vertical-align: top;
    display: inline-block;
    padding-top: 1px;
}
.hmprod .product_image_price {font-size:.8em}
#sympathy-category-banner {max-width: 100%}
#sympathy-subcategories-nav {float: left; width: 218px}
#sympathy-subcategories-view {float: left; width: 774px}

/*************************************
        HOT DEALS STYLES
*************************************/

.filterBar{    float:left; width: 19%; }
.filterBar h3{ font-size: 20px; margin: 5px 0px;    color: #666; text-transform: uppercase;    padding-left: 10px; }
.filterBar .filter-select{ background-color: #fff; width: 95%; padding: 2px 0; border: 1px solid #ddd; margin-left: 10px; display: block; }
.filterBar .colorTitle, .filterBar .priceTitle{    margin: 15px auto 5px; border-bottom: 1px solid #ddd; padding-bottom: 5px; padding-left: 10px; }
.filterBar .colorBox, .filterBar .priceBox{    padding-left: 15px; }
.filterBar .colorBox label, .filterBar .priceBox label{    display: block; }
.filterBar .colorBox label input, .filterBar .priceBox label input{    margin-right: 5px; }

.prodResult.prodPanel{ float: right; width: 80%; }
.prodResult .prodBox{ margin: 5px; width: 23.5%; float: left; border: 1px solid #f8f8f8; background: #f8f8f8; position: relative; z-index: 10; }


.blog-post img, .blog-image-wrap img, #awarded-box .img-wrapper img, .round-content-box img {
    max-width: 100%;
    max-height: 100% !important;
}
.col-md-12.noleftmgh {
    padding-left: 0px;
    padding-right: 0px;
}
.col-md-9.noleftmg {
    padding-left: 0px;
}
.col-md-3.noleftmg {
    padding-right: 0px;
}

.round-resp {
    float: left;
    margin-right: 2%;
    width: 31%;
    margin-top: 10px;
}

.split-two {
    float: left;width: 33%
}

.af-news #skip-link {
    margin: 0;
}

button.donate-request {
    background: url(../img/1.0.1/news/button.jpg)no-repeat;
    cursor: pointer;
    border: none;
    max-width: 637px;
    width: 100%;
    height: 135px;
    margin: 0 auto;
    background-size: contain;
}

.af-news .continue-reading-link a {
    text-decoration: none;
}

.blog-wrapper {
    background-color: #fff;
}

.blog-posts {
    height: 1460px;
    overflow-y: auto;
    float: left;
    position: relative;
}

#content-blog {
    float: left;
    position: relative;
    padding: 0 0 20px 0;
}

.af-news #content-sidebar .light-content-box {
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: #f1f0f0;
    margin-bottom: 20px;
}

.af-news .light-content-box h2.widget-title {
    padding: 10px 0 10px 0;
    color: #2b2b2b;
    text-align: center;
    font-size: 2.4em;
    font-family: 'Gloria Hallelujah', cursive;
    font-weight: 500;
    border: none;
}

.testimonials-list li {
    padding: 0 3px 20px 0;
    font-style: italic;
}

#content-blog .blog-post p {
    padding-top: 0;
    font-size: 1em;
    font-weight: 500;
}

#content-blog .blog-post .blog-title {
    margin-top: 0;
}

#content-blog .blog-post .blog-title a {
    text-decoration: none;
    font-weight: 600;
}

#content-blog .blog-post .blog-title a:visited {
    color: black;
}

.af-news .blog-post {
    margin-bottom: 0px;
    background: #ffffff;
    border: 0;
    padding: 8px
}

.join-btn a, .join-btn a:visited, .join-btn a:hover, .join-btn a:link{
    text-decoration: none;
    color:white
}
.carouselHero{
    -webkit-box-shadow: 0 1px 5px 0 #4a4a4a;
    -moz-box-shadow: 0 1px 5px 0 #4a4a4a;
    box-shadow: 0 1px 5px 0 #4a4a4a;
    margin:35px auto;
}
.custom-loader {
    border: 5px solid white;
    border-top: 5px solid #5cb85c;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}
#getCustomProduct {
	.close {
		opacity: 1;
	}
}