// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$sprite-accountIconDelete: (161px, 142px, -161px, -142px, 22px, 22px, 275px, 200px, '../sprites/1.0.1/sprites.png', 'account-icon-delete');
$sprite-accountIconEdit: (31px, 142px, -31px, -142px, 24px, 24px, 275px, 200px, '../sprites/1.0.1/sprites.png', 'account-icon-edit');
$sprite-accountIconInfo: (185px, 142px, -185px, -142px, 22px, 22px, 275px, 200px, '../sprites/1.0.1/sprites.png', 'account-icon-info');
$sprite-accountIconInvoice: (57px, 142px, -57px, -142px, 24px, 24px, 275px, 200px, '../sprites/1.0.1/sprites.png', 'account-icon-invoice');
$sprite-accountIconPrint: (83px, 142px, -83px, -142px, 24px, 24px, 275px, 200px, '../sprites/1.0.1/sprites.png', 'account-icon-print');
$sprite-accountIconProblem: (109px, 142px, -109px, -142px, 24px, 24px, 275px, 200px, '../sprites/1.0.1/sprites.png', 'account-icon-problem');
$sprite-accountIconReorder: (135px, 142px, -135px, -142px, 24px, 24px, 275px, 200px, '../sprites/1.0.1/sprites.png', 'account-icon-reorder');
$sprite-accountLoginIconQuestion: (0px, 77px, 0px, -77px, 48px, 48px, 275px, 200px, '../sprites/1.0.1/sprites.png', 'account-login-icon-question');
$sprite-arrowSub: (264px, 15px, -264px, -15px, 9px, 9px, 275px, 200px, '../sprites/1.0.1/sprites.png', 'arrow-sub');
$sprite-arrow2: (72px, 127px, -72px, -127px, 5px, 9px, 275px, 200px, '../sprites/1.0.1/sprites.png', 'arrow2');
$sprite-boxErrorBig: (65px, 30px, -65px, -30px, 46px, 44px, 275px, 200px, '../sprites/1.0.1/sprites.png', 'box-error-big');
$sprite-boxSuccessBig: (113px, 30px, -113px, -30px, 46px, 44px, 275px, 200px, '../sprites/1.0.1/sprites.png', 'box-success-big');
$sprite-breadcrumbHome: (79px, 127px, -79px, -127px, 5px, 9px, 275px, 200px, '../sprites/1.0.1/sprites.png', 'breadcrumb-home');
$sprite-breadcrumbSpacer: (161px, 67px, -161px, -67px, 6px, 6px, 275px, 200px, '../sprites/1.0.1/sprites.png', 'breadcrumb-spacer');
$sprite-checkLarge: (209px, 142px, -209px, -142px, 22px, 22px, 275px, 200px, '../sprites/1.0.1/sprites.png', 'check-large');
$sprite-checkoutHintArrow: (0px, 171px, 0px, -171px, 20px, 29px, 275px, 200px, '../sprites/1.0.1/sprites.png', 'checkout-hint-arrow');
$sprite-checkoutLoginListStyle: (253px, 15px, -253px, -15px, 9px, 11px, 275px, 200px, '../sprites/1.0.1/sprites.png', 'checkout-login-list-style');
$sprite-delete: (237px, 15px, -237px, -15px, 14px, 13px, 275px, 200px, '../sprites/1.0.1/sprites.png', 'delete');
$sprite-error: (257px, 142px, -257px, -142px, 16px, 16px, 275px, 200px, '../sprites/1.0.1/sprites.png', 'error');
$sprite-footerAddress1: (0px, 0px, 0px, 0px, 275px, 13px, 275px, 200px, '../sprites/1.0.1/sprites.png', 'footer_address1');
$sprite-footerAddress2: (0px, 127px, 0px, -127px, 70px, 13px, 275px, 200px, '../sprites/1.0.1/sprites.png', 'footer_address2');
$sprite-footerAddress3: (65px, 15px, -65px, -15px, 170px, 13px, 275px, 200px, '../sprites/1.0.1/sprites.png', 'footer_address3');
$sprite-helpSdp: (45px, 171px, -45px, -171px, 16px, 16px, 275px, 200px, '../sprites/1.0.1/sprites.png', 'help-sdp');
$sprite-iconInfoLarge: (176px, 77px, -176px, -77px, 32px, 32px, 275px, 200px, '../sprites/1.0.1/sprites.png', 'icon-info-large');
$sprite-info: (63px, 171px, -63px, -171px, 16px, 16px, 275px, 200px, '../sprites/1.0.1/sprites.png', 'info');
$sprite-lockIcon: (244px, 77px, -244px, -77px, 27px, 30px, 275px, 200px, '../sprites/1.0.1/sprites.png', 'lockIcon');
$sprite-lockIconSml: (135px, 171px, -135px, -171px, 14px, 16px, 275px, 200px, '../sprites/1.0.1/sprites.png', 'lockIconSml');
$sprite-navIconClose: (22px, 171px, -22px, -171px, 21px, 20px, 275px, 200px, '../sprites/1.0.1/sprites.png', 'navIconClose');
$sprite-orderStatusWarning: (210px, 77px, -210px, -77px, 32px, 32px, 275px, 200px, '../sprites/1.0.1/sprites.png', 'order-status-warning');
$sprite-profileArrowLeft: (81px, 171px, -81px, -171px, 16px, 16px, 275px, 200px, '../sprites/1.0.1/sprites.png', 'profile-arrow-left');
$sprite-profileArrowRight: (99px, 171px, -99px, -171px, 16px, 16px, 275px, 200px, '../sprites/1.0.1/sprites.png', 'profile-arrow-right');
$sprite-profileIconAdd: (233px, 142px, -233px, -142px, 22px, 22px, 275px, 200px, '../sprites/1.0.1/sprites.png', 'profile-icon-add');
$sprite-quote: (0px, 142px, 0px, -142px, 29px, 27px, 275px, 200px, '../sprites/1.0.1/sprites.png', 'quote');
$sprite-rewardPointsBig: (0px, 15px, 0px, -15px, 63px, 60px, 275px, 200px, '../sprites/1.0.1/sprites.png', 'reward-points-big');
$sprite-secure: (117px, 171px, -117px, -171px, 16px, 16px, 275px, 200px, '../sprites/1.0.1/sprites.png', 'secure');
$sprite-step1: (50px, 77px, -50px, -77px, 40px, 40px, 275px, 200px, '../sprites/1.0.1/sprites.png', 'step1');
$sprite-step2: (92px, 77px, -92px, -77px, 40px, 40px, 275px, 200px, '../sprites/1.0.1/sprites.png', 'step2');
$sprite-step3: (134px, 77px, -134px, -77px, 40px, 40px, 275px, 200px, '../sprites/1.0.1/sprites.png', 'step3');
$sprite-subNavArrow: (161px, 30px, -161px, -30px, 55px, 35px, 275px, 200px, '../sprites/1.0.1/sprites.png', 'subNavArrow');
$sprite-subNavArrowClose: (218px, 30px, -218px, -30px, 55px, 35px, 275px, 200px, '../sprites/1.0.1/sprites.png', 'subNavArrowClose');
$spritesheetSprites: (  $sprite-accountIconDelete ,  $sprite-accountIconEdit ,  $sprite-accountIconInfo ,  $sprite-accountIconInvoice ,  $sprite-accountIconPrint ,  $sprite-accountIconProblem ,  $sprite-accountIconReorder ,  $sprite-accountLoginIconQuestion ,  $sprite-arrowSub ,  $sprite-arrow2 ,  $sprite-boxErrorBig ,  $sprite-boxSuccessBig ,  $sprite-breadcrumbHome ,  $sprite-breadcrumbSpacer ,  $sprite-checkLarge ,  $sprite-checkoutHintArrow ,  $sprite-checkoutLoginListStyle ,  $sprite-delete ,  $sprite-error ,  $sprite-footerAddress1 ,  $sprite-footerAddress2 ,  $sprite-footerAddress3 ,  $sprite-helpSdp ,  $sprite-iconInfoLarge ,  $sprite-info ,  $sprite-lockIcon ,  $sprite-lockIconSml ,  $sprite-navIconClose ,  $sprite-orderStatusWarning ,  $sprite-profileArrowLeft ,  $sprite-profileArrowRight ,  $sprite-profileIconAdd ,  $sprite-quote ,  $sprite-rewardPointsBig ,  $sprite-secure ,  $sprite-step1 ,  $sprite-step2 ,  $sprite-step3 ,  $sprite-subNavArrow ,  $sprite-subNavArrowClose);
$spritesheet: (275px, 200px, 'sprites.png', $spritesheetSprites);

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
