@media  ( max-width : 990px ){

    .footerLinks{ margin:0 auto; width:80%; padding-top:10px; }
    .footLinkBox{ margin:0 auto; float:left; padding:5px;}
    .footFirstLink{ width:45%; }
    .footSecondLink{ width:45%; float:right;}
    .footThirdLink{ width:100%; margin-top:20px; text-align:center;}

    .footLinkBox h3{ margin:10px auto; font-size:20px; color:#555; }
    .footLinkBox a{ margin:3px auto; color:#000; font-size:14px; }
    .footLinkBox a:hover{ text-decoration:none; }
    .footLinkBoxInner{ float:none; width:100%; }
    .footThirdLink a{ margin:10px 20px; display:inline-block; }

    .socialIcons a{ padding:0 5px; text-decoration:none;}

    .footerContent{ margin:0 auto; width:90%; border-top:1px solid #ec92da; }
    .footerContent p.pin{ font-family:Arial, Helvetica, sans-serif; font-size:15px; color:#333; text-align:center; }
    .footerContent p{ text-align:center; font-family:Arial, Helvetica, sans-serif; font-size:15px; margin-bottom:10px; }

    #int-main-fullwidth #big-headline{ margin: 10px auto 10px; padding-left: 10px; }

    .light-content-box p{ font-size:14px; }
    .lft-links a{ font-size:14px; }

    .clearNone {
        display:block;
        clear: both;
    }

    #product-options .prod-opt {
        width: 100%;
    }

    .desktopProds{ display:none; }
    .mobileProds{ display:block; }

    .prodPanel{ width:80%; margin:0 auto; }
    .prodBox{ margin:5px; width:48%; float:left; border:1px solid #f8f8f8; background:#f8f8f8; position:relative; z-index:10; }
    .prodBox:hover{ border:1px solid #f1f1f1; background:#f1f1f1; }
    .prodBox a img{ display:block; width:100%; }
    .prodBox a img.sameDay{ width:auto; position:absolute; top:-5px; right:-8px; z-index:15; }

    .prodInfo{ text-align:center; padding:10px; }
    .prodInfo a{ color:#067919; font-size:13px; font-weight:500; height:40px; display:block; margin:0 auto; }
    .prodInfo a:hover{ text-decoration:none; }
    .prodInfo p{ font-size:12px; }
    .prodInfo p.price{ font-size:14px; font-weight:bold; }
    .prodInfo p.price span{ color:#F00; text-decoration:line-through; }

    #breadcrumb {
        padding-left:10px;
    }

    .desktop{
        display:none;
        visibility:hidden;
    }

    .left-sec, .right-sec{
        float:none;
        width:100%;
    }
    .col-md-9.noleftmg{
        padding-left:15px;
    }
    .col-md-3.noleftmg{
        padding-left:15px;
    }

    .col-md-12.noleftmgh{
        padding-left:15px;
        padding-right:15px;
    }

    .hmprod{
        display:inline-block;
        float:none;
    }
    #sympathy-subcategories-nav {
        width: 768px;
        text-align: center;
    }
    #sympathy-subcategories-view {
        width: 100%; overflow: hidden
    }
    .sympathy-collections-row .hmprod {
        display:block;
        float:left;
    }
}

@media  ( max-width : 768px ){
    .light-content-box p{ font-size:13px; }
    .round-content-box label{ width:100%; }

    .breadcrumb{ width:100%; }

    .filterBar .filter-select{ background-color:#eee; width:95%; padding:2px 0; border:1px solid #eee; }

    #delivery-popup {
        max-width:561px;
    }

    #sympathy-subcategories-nav {
        width: 100%;
    }
}

@media  ( max-width : 667px ){
    .hide-667{ display:none; }

    .prodPanel{ width:100%; margin:0 auto; }
    .prodBox{ margin:5px; width:48%; float:left; border:1px solid #f8f8f8; background:#f8f8f8; position:relative; z-index:10; }
    .prodBox:hover{ border:1px solid #f1f1f1; background:#f1f1f1; }
    .prodBox a img{ display:block; width:100%; }
    .prodBox a img.sameDay{ width:auto; position:absolute; top:-5px; right:-8px; z-index:15; }

    .prodInfo{ text-align:center; padding:10px; }
    .prodInfo a{ color:#067919; font-size:13px;  font-weight:500; height:40px; display:block; margin:0 auto; }
    .prodInfo a:hover{ text-decoration:none; }
    .prodInfo p{ font-size:12px; }
    .prodInfo p.price{ font-size:14px; font-weight:bold; }
    .prodInfo p.price span{ color:#F00; text-decoration:line-through; }
    .hmprod{
        display:inline-block;
        float:left;
    }
    #navFilterItems {
        display: block!important;
    }
    .filterBar{ float:none; width:100%; position:relative; padding-top: 45px; }
    .filterBar h3{  font-size:14px; font-weight: bold; margin:5px 0px; color:#666; text-transform:uppercase; padding-left:10px; }

    .filterBar .filter-select{ background-color:#fff; width:auto; padding:2px 0; border:1px solid #ddd; margin-left:10px;}
    .filterBar .deliveryTitle{ padding-top:0px; }
    .filterBar .colorTitle{ margin:0px auto 5px; border:1px solid #ddd; padding:10px; width:145px; text-align:center; cursor:pointer; position: absolute; top: 0; left: 15px; z-index: 1; }
    .filterBar .priceTitle{ margin:0px auto 5px; border:1px solid #ddd; padding:10px; width:145px; text-align:center; cursor:pointer; position: absolute; top: 0; left: 165px; z-index: 1; }
    .filterBar .shop-by-link:hover,
    .filterBar .active{ background-color: #f5f5f5; }
    .filterBar label{ min-width: 112px; }
    .filterBar .colorBox, .filterBar .priceBox{ padding:10px 10px 20px; display:none; border-top:1px solid #ddd; margin:5px auto 10px; border-bottom:1px solid #eee; }
    .filterBar .colorBox label{ display:inline-block; margin:5px 10px; }
    .filterBar .priceBox label{ display:inline-block; width:32%; }
    .filterBar .colorBox label input, .filterBar .priceBox label input{ margin-right:5px; }


    #delivery-popup {
        max-width:600px;
    }
}

@media ( max-width : 600px ){

    .footFirstLink{ width:48%; border-right:1px dashed #333; }
    .footSecondLink{ width:48%; float:right;}

    #int-main-fullwidth #big-headline {
        font-size:25px;
        margin-top: 10px;
    }
    .category-title {
        font-size: 25px!important;
    }
    .prodResult.prodPanel{ float:none; width:90%; text-align:center;}


    .round-content-box .text{
        width:98%;
    }
    .round-resp {
        float: none;
        width:100%;
    }

    #delivery-popup {
        max-width:500px;
    }
    .footLinkBox4Col{
        width: 100%;
    }
    .footLinkBox2Col{
        width: 100%;
        justify-content: normal;
    }
}

@media ( max-width : 500px ){
    #delivery-popup {
        max-width:400px;
    }
}

@media ( max-width : 430px ){
    .socialIcons a{ margin:5px 3px; display:inline-block; }
    .footLinkBox{ margin:0 auto; float:left; padding:5px; border-bottom:1px dashed #000; padding-bottom:10px;}

    #int-main-fullwidth p { padding: 0px 0px 0px 0px }

    /* remove content under menu and above special notice */
    #breadcrumb { display: none; } /* home */
    #container > div.clear:first-child { display: none; } /* city */

    /* remove image in special notice */
    #special-notice-home > p > img { display: none; } /* home */
    #special-notice-city > p > img { display: none; } /* city */
    #special-notice > p > img { display: none; } /* unknown (may not be needed)*/

    /* full width for special notice , font size and weight */
    #special-notice-home, #special-notice-city, #special-notice {
        margin-left: -15px;
        margin-right: -15px;
        font-size: 12px;
        color: $white;
        border-radius: 2px;
        margin-bottom: 6px;
    }

    /* special notice code css */
    #special-notice-home,
    #special-notice-city,
    #special-notice {
        p {
            strong {
                a {
                    text-decoration: none;
                    color: $white;
                }
            }
        }
    }

    /* margin for search bar on mobile */
    #topSearchBoxContainer{
        margin-right: 30px;
        margin-left: 30px;
    }

    /* remove banner */
    .carouselHero{ display: none; }

    /* remove headlines */
    div.page-type-city div.productSec h2.big-headline { display: none; }
    div.page-type-home div.productSec h2.big-headline { display: none; }

    /* remove accessibility link */
    #accessibility-help { display: none; }

    /*************************/

    .mobile-only { display: block; height: 140px }

    .prodPanel{ width:100%; margin:0 auto; }
    .prodBox{ margin:5px; width:47%; float:left; border:1px solid #f8f8f8; background:#f8f8f8; position:relative; z-index:10; }
    .prodBox:hover{ border:1px solid #f1f1f1; background:#f1f1f1; }
    .prodBox a img{ display:block; width:100%; }
    .prodBox a img.sameDay{ width:auto; position:absolute; top:-5px; right:-8px; z-index:15; }

    .prodInfo{ text-align:center; padding:10px; }
    .prodInfo a{ color:#067919; font-size:13px; font-weight:500; height:40px; display:block; margin:0 auto; }
    .prodInfo a:hover{ text-decoration:none; }
    .prodInfo p{ font-size:12px; }
    .prodInfo p.price{ font-size:14px; font-weight:bold; }
    .prodInfo p.price span{ color:#F00; text-decoration:line-through; }

    .mainContent p{ font-size:14px; color:#333; text-align:justify; }


    .filterBar .filter-select{ margin-bottom:76px; }
    .filterBar .priceBox label{ display:inline-block; width:45%; }

    .prodResult.prodPanel .prodBox{ margin:5px; width:47%; float:left; border:1px solid #f8f8f8; background:#f8f8f8; position:relative; z-index:10; }


    #delivery-popup {
        max-width:300px;
    }

    #general-popup-holder {
        margin: 0px;
        left: 14px;
        right: 14px;
        width: auto
    }

    #accessibility-help { display: none }
    .hmprod {
        width: 49%;
        margin: 0px;
        border: 0px;

        .product_image_caption {
            display: block;
        }

        .with_today_label {
            height: 104px;
        }

        .without_today_label {
            height: 84px;
        }
    }
    #sympathy-category-banner { width: 100% }
    #sympathy-subcategories-nav { float: none; width: 100% }
    #sympathy-subcategories-view { float: none; width: 100%; overflow: hidden }
    #sympathy-subcategories-view .show-more { font-size: 1.1em }
    .sympathy-collections-banner {
        display: none
    }
    .sympathy-collections-row-title {
        text-align: center
    }
    .sympathy-collections-row-description {
        display: none
    }
    #category-head { width: auto }
    #category-headline { font-size: 28px }
    #sympathy-collection-link { display: none }
    .subcat-show-sympathy .colorTitle { display: none }
    .subcat-show-sympathy .colorBox { display: none }
    .subcat-show-sympathy .priceTitle { display: none }
    .subcat-show-sympathy .priceBox { display: none }
    .subcat-show-sympathy .mobile-only { display: none }
    .collection-head-image { display: none }
    #sympathy-items { margin: 0px auto }
    .sympathy-item { display: block }

    #topLinks{ padding-top: 2px; margin-right: -10px; height: 31px; }
    #topLinks .fa.mr-2{ margin-right: 2.5px !important; }
}

@media  ( max-width : 375px ){

    .footerLinks{ margin:0 auto; width:90%; padding-top:10px; }

    .footFirstLink{ width:100%; float:none; border-right:none; }
    .footSecondLink{ width:100%; float:none;  border-bottom:none;}
    .footThirdLink{ width:100%; float:none;  border-bottom:none;}

    .footLinkBoxInner{ float:none; width:100%;  border-bottom:1px dashed #000; padding-bottom:10px;}
    .footThirdLink a{ margin:10px 20px; display:inline-block; }

    .socialIcons a{ padding:0; }

    .footerContent{ margin:0 auto; width:90%; border-top:1px solid #ec92da; }
    .footerContent p.pin{ font-family:Arial, Helvetica, sans-serif; font-size:15px; color:#333; text-align:center; }
    .footerContent p{ text-align:center; font-family:Arial, Helvetica, sans-serif; font-size:12px; margin-bottom:10px; }

    .prodPanel{ width:100%; margin:0 auto; }
    .prodBox{ margin:5px auto; width:80%; float:none; border:1px solid #f8f8f8; background:#f8f8f8; position:relative; z-index:10; }
    .prodBox:hover{ border:1px solid #f1f1f1; background:#f1f1f1; }
    .prodBox a img{ display:block; width:100%; }
    .prodBox a img.sameDay{ width:auto; position:absolute; top:-5px; right:-8px; z-index:15; }

    .prodInfo{ text-align:center; padding:10px; }
    .prodInfo a{ color:#067919; font-size:13px; font-weight:500; height:40px; display:block; margin:0 auto; }
    .prodInfo a:hover{ text-decoration:none; }
    .prodInfo p{ font-size:12px; }
    .prodInfo p.price{ font-size:14px; font-weight:bold; }
    .prodInfo p.price span{ color:#F00; text-decoration:line-through; }

    .prodResult.prodPanel .prodPanel{ width:100%; margin:0 auto; }
    .prodResult.prodPanel .prodBox{ margin:5px auto; width:80%; float:none; border:1px solid #f8f8f8; background:#f8f8f8; position:relative; z-index:10; }

    #accessibility-help { display: none }
}

@media  ( max-width : 320px ){

    .socialIcons a{ padding:0; }

    p.goldenTxt{ font-family:"Times New Roman", Times, serif; font-size:13px; color:#855403; line-height:25px; }

    #accessibility-help { display: none }
}

@media (min-width: 410px) {
    #toplink-login{
        display: inline-block;
    }
    #nav-login{
        display: none!important;
    }
}
@media (min-width: 431px) {
    #topLinks{
        text-align: center;
    }
}
@media (min-width: 510px) {
    #topOffer{
        display: block;
    }
}
@media (min-width: 580px) {
    .topLinksItem, #contact-us-sign, #call-number-mobile{
        padding: 5px 10px;
    }
}
@media (min-width: 620px) {
    #nav-delivery-status{
        display: none!important;
    }
}
@media (min-width: $screen-sm) {
    .highCallVolume{
        text-align: left;
        max-width: 100%;
    }
    .modal-dialog-sm{
        width: 340px;
    }
}
@media (min-width: $screen-md) {
    #topOfferHeader,
    .topLinksItem,
    #contact-us-sign,
    #call-number-mobile {
        font-size: 14px;
    }
    #topOfferHeader {
        line-height: 1.5;
    }
    .topLinksItem,
    #contact-us-sign,
    #call-number-mobile {
        padding-top: 8px;
        padding-bottom: 8px;
    }
    #nav{
        border-top: 1px solid #ccc;
        .navBtn, .subnavArrow {
            display: none;
        }
        ul{
            width: 100%;
            margin: 0 auto;
            text-align: center;
            display: block;
            li {
                display: inline-block;
                position: relative;
                z-index: 400;
                border-bottom-width: 0px;
                a, > span{
                    display: inline-block;
                    margin: 0;
                    font-family: 'Arial','Helvetica','sans-serif';
                    color: $text-color;
                    text-decoration: none;
                    font-size: 14px;
                    font-weight: bold;
                    cursor: pointer;
                }
                a{
                    padding: 12px 18px;
                }
                ul{
                    display: none;
                    margin: 0;
                    padding: 0;
                    background-color: $green;
                    position: absolute;
                    top: 43px;
                    left: 0;
                    width: 250px;
                    z-index: 480;
                    border-top-color: $green;
                    box-shadow: $box-shadow;
                    li {
                        display: block;
                        margin: 0;
                        padding: 0;
                        position: relative;
                        a, > span {
                            float: none;
                            display: block;
                            color: $white;
                            &:hover{
                                background: $green-light;
                            }
                        }
                        a{
                            padding: 12px 20px;
                        }
                    }
                }
                &:last-of-type{
                    ul{
                        left: auto;
                        right: 0;
                    }
                }
                &:hover{
                    a, > span{
                        color: $white;
                        background-color: $green;
                        @include transition(none);
                    }
                }
            }
            li.mobile-avas-pin {
                display: none;
            }
        }
    }
    #topDiscountCode{
        display: inline-block!important;
        cursor: pointer;
    }
    #topDiscountCodeMiddle{
        display: none;
    }
    #topLinks{
        text-align: right;
        .phoneHideOnMobile{
            display: inline-block!important;
        }
        #call-number-mobile{
            display: none;
        }
        .navBtn{
            display: none;
        }
    }
    #topOfferHeader{
        text-align: left;
    }
    #virtualFloristBox{
        position: relative;
        #virtualFloristZips{
            margin-bottom: 18px;
        }
    }

    #virtualFloristAbsolute{
        width: auto!important;
        bottom: 15px;
    }
}
@media  (min-width: 981px) {
    .col-5 .hmprod:nth-child(5n+1) {
        clear: both;
    }

    .col-4 .hmprod:nth-child(4n+1) {
        clear: both;
    }
}
