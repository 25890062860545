// Form CSS floating labels
.form-floating {
    position: relative;
    .bootstrap-select{
        min-height: 42px;
        .form-btn{
            padding: 15px 8px 6px;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: block;
            z-index: 1;
        }
        .dropdown-toggle{
            &:focus{
                outline: 0!important;
            }
        }
    }
}
.form-floating > .form-control,
.form-floating > .form-select {
    height: 42px;
    padding: 1rem 0.75rem;
    &#delivery_date{
        background-color: $white;  
        cursor: pointer;
    }
}
.form-floating > textarea {
    height: auto!important;
}
.form-floating > label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 1rem 0.75rem;
    pointer-events: none;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
    z-index: 1;
    opacity: .85;
    font-weight: 600;
    @media (prefers-reduced-motion: reduce) {
        transition: none;
    }
}
// Hack for IE 11
@media all and (-ms-high-contrast:none) {
    .form-floating > label {
        opacity: 0.85;
        transform: scale(0.785) translateY(-0.75rem) translateX(0.15rem);
        font-weight: 600;
    }
    .form-floating > .form-control{
        padding: 14px 8px 6px;
    }
    .form-floating > .form-select {
        padding: 14px 6px 6px;
    }
}
.form-floating:not(.empty) > .form-control::-webkit-input-placeholder {
    color: transparent;
}
.form-floating:not(.empty) > .form-control::-moz-placeholder {
    color: transparent;
}
.form-floating:not(.empty) > .form-control::placeholder {
    color: transparent;
}
.form-floating:not(.empty) > .form-control:not(:-moz-placeholder-shown) {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem;
}
.form-floating:not(.empty) > select:not(:-moz-placeholder-shown) {
    padding-left: 0.425rem;
}
.form-floating:not(.empty) > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem;
}
.form-floating:not(.empty) > select:focus, .form-floating > select:not(:placeholder-shown) {
    padding-left: 0.425rem;
}
.form-floating:not(.empty) > .form-control:-webkit-autofill {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem;
}
.form-floating:not(.empty) > select:-webkit-autofill {
    padding-left: 0.425rem;
}
.form-floating:not(.empty) > .form-control:not(:-moz-placeholder-shown) ~ label {
    opacity: 0.85;
    transform: scale(0.785) translateY(-0.65rem) translateX(0.15rem);
    font-weight: 600;
}
.form-floating:not(.empty) > .form-control:focus ~ label,
.form-floating:not(.empty) > .form-control:not(:placeholder-shown) ~ label {
    opacity: 0.85;
    transform: scale(0.785) translateY(-0.65rem) translateX(0.15rem);
    font-weight: 600;
}
.form-floating:not(.empty) > .form-control:-webkit-autofill ~ label {
    opacity: 0.85;
    transform: scale(0.785) translateY(-0.65rem) translateX(0.15rem);
    font-weight: 600;
}