.avasModal{
    .modal-header{
        background-image: linear-gradient(to bottom, $green-light 0%, $green-light 40%, $green 100%)!important;
        background: $green-light;
        border-bottom-color: $green-light!important;
        border-radius: 6px!important;
        padding: 8px 24px!important;
        margin: 0 -5px;
        box-shadow: 0 0 4px $black;
        .modal-title{
            color: $white!important;
            text-decoration: none!important;
            text-transform: uppercase;
            font-weight: 600!important;
            text-shadow: 1px 1px 1px $gray-dark;
            font-size: 17px!important;
        }
    }
    .well{
        text-align: center;
        a{
            padding: initial!important;
            font-size: 35px!important;
            color: $text-color!important;
            text-decoration: none!important;
            &:hover, &focus{
                color: $green!important;
            }
        }
    }
}
#phone-modal{
    .modal-header{
        padding-bottom: 0;
    }
    .btn{
        margin-top: 10px;
        &:first-child{
            margin-top: 0;
        }
    }
}

#general-popup-holder {
    background: #fff;
    width: 700px;
    -moz-box-shadow: 0 0 13px #666;
    -webkit-box-shadow: 0 0 13px #666;
    box-shadow: 0 0 13px #666;
    position: absolute;
    top: 0px;
    left: 50%;
    margin-left: -350px;
    z-index: 10000;
}
#general-popup-background {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    background: #fff;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=70);
    -moz-opacity: 0.7;
    -khtml-opacity: 0.7;
    -webkit-opacity: 0.7;
    opacity: 0.7;
}
#general-popup-wide-holder, #facebox-popup-holder {
    background: #fff;
    width: 80%;
    -moz-box-shadow: 0 0 13px #666;
    -webkit-box-shadow: 0 0 13px #666;
    box-shadow: 0 0 13px #666;
    position: absolute;
    top: 0px;
    left: 10%;
    margin: 0 auto;
    z-index: 10000;
}
#general-popup-wide-background, #facebox-popup-background {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    background: #fff;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=70);
    -moz-opacity: 0.7;
    -khtml-opacity: 0.7;
    -webkit-opacity: 0.7;
    opacity: 0.7;
}

#sdp-popup ul.upgrades li, #sdp-popup ul.uptxt li {
    float: left;
    position: relative;
    width: 200px;
    padding-left: 10px;
    padding-right: 10px;
}

.ui-widget-overlay {
    position: fixed!important;  
    background: rgba(0,0,0,.45)!important;    
    opacity: 0.9!important;    
    filter: Alpha(Opacity=90)!important;  
    &.ui-front{
        z-index: 1061!important;
    }
}

.ui-dialog{
    z-index: 1062!important;  
}
.ui-dialog .ui-dialog-title{  
    white-space: normal!important;
    overflow: auto!important;
}

/*
* CSS class to remove the top right dialog titlebar close option
*/
.no-close .ui-dialog-titlebar-close {
    display: none;
}