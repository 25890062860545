.swiper-testimonials {
    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev,
    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next{
        display: none;
    }
}
.swiper-button-next, .swiper-button-prev {
    position: absolute;
    top: 50%;
    width: 35px;
    height: 35px;
    z-index: 10;
    cursor: pointer;
}
.swiper-button-prev {
    left: 0 !important;
}
.swiper-button-next {
    right: 0 !important;
}
